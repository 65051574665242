import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {TenantDto} from "../../model/TenantDto";
import TenantBooleanRadioFormGroup from "./TenantBooleanRadioFormGroup";


interface Props {
    show: boolean;
    onHide: () => void;
    submitCallback: (tenant: TenantDto) => void;
}

const schema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    employerContribution: Yup.number().nullable(),
    employerFixedContribution: Yup.number().nullable(),

        // employerContribution: Yup.number().when('employerFixedContribution', {
        //     is: (employerFixedContribution: number) => employerFixedContribution === undefined,
        //     then: () =>
        //         Yup.number().required("One contribution is required"),
        //     otherwise: () =>
        //         Yup.number().nullable()
        // }),
        // employerFixedContribution: Yup.number().when('employerContribution', {
        //     is: (employerContribution: number) => employerContribution === undefined,
        //     then: () =>
        //         Yup.number().required("One contribution is required"),
        //     otherwise: () =>
        //         Yup.number().nullable()
        // }),
    requireConfirmation: Yup.boolean().required('Contribution is required'),
    useGrossVehiclePrice: Yup.boolean().required('Contribution is required'),
});

const TenantModal: React.FC<Props> = ({ show, onHide, submitCallback, }) => {

    function getInitTenant(): TenantDto {
        return {id: 0, norm: '', title: '', employerContribution: undefined, employerFixedContribution: undefined,  signupCode: '', contractInfo: '', parent: undefined, children: undefined, orderEmail: undefined, registerEmail: undefined, requireConfirmation: undefined, useGrossVehiclePrice: undefined}
    }

    const formik = useFormik({
        initialValues: getInitTenant(),
        validationSchema: schema,
        onSubmit: values => {
            submitCallback(values);
            onHide();
        },
    });

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create Tenant</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.title}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Contribution</Form.Label>
                        <Form.Control
                            type="number"
                            name="employerContribution"
                            value={formik.values.employerContribution}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.employerContribution}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.employerContribution}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fixed Contribution</Form.Label>
                        <Form.Control
                            type="number"
                            name="employerFixedContribution"
                            value={formik.values.employerFixedContribution}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.employerFixedContribution}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.employerFixedContribution}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Signup code</Form.Label>
                        <Form.Control
                            type="text"
                            name="signupCode"
                            value={formik.values.signupCode}
                            onChange={formik.handleChange}
                            isInvalid={!!formik.errors.signupCode}
                        />
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.signupCode}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <TenantBooleanRadioFormGroup name={"requireConfirmation"} value={formik.values.requireConfirmation} setFieldValue={formik.setFieldValue} touched={formik.touched} errors={formik.errors}/>
                    <TenantBooleanRadioFormGroup name={"useGrossVehiclePrice"} value={formik.values.useGrossVehiclePrice} setFieldValue={formik.setFieldValue} touched={formik.touched} errors={formik.errors}/>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                    <Button  variant="primary" type="submit">
                        Create
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default TenantModal;


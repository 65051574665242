import {ChildParentTenantDto, TenantDto} from "../../model/TenantDto";
import {Button, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {useSaveTenant} from "../../api/tenants/useSaveTenant";
import {useNavigate, useParams} from "react-router-dom";
import {useGetTenant} from "../../api/tenants/useTenant";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {useGetTenants} from "../../api/tenants/useTenants";
import TenantBooleanRadioFormGroup from "../../components/tenant/TenantBooleanRadioFormGroup";


export default function TenantsEdit() {
    const navigate = useNavigate();

    const {loading, data, error, execute} = useGetTenant();
    const {loading: loadingAll, data: dataAll, error:errorAll, execute:executeAll} = useGetTenants();

    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveTenant();
    const [showModal, setShowModal] = useState(false);
    const {tenantId} = useParams();
    const [parent, setParent] = useState<ChildParentTenantDto>()


    useEffect(() => {
        executeGet();
    }, [tenantId]);

    function executeGet() {
        executeAll();
        if (tenantId && !Number.isNaN(tenantId)) {
            execute(Number.parseInt(tenantId));
        }
    }

    if(!data) return (<Loading/>);

    const emailListSchema = Yup.string().required().test({
        name: 'emails',
        message: 'Invalid email format',
        test: function(value: string | undefined) {
            if (!value) return false;

            const emails = value.split(';').map(email => email.trim())
                .filter(email => email.length > 0);

            if (emails.length === 0) {
                return this.createError({ message: 'At least one valid email is required' });
            }

            const emailSchema = Yup.string().email();
            const invalidEmails = emails.filter(email => {
                try {
                    emailSchema.validateSync(email);
                    return false;
                } catch {
                    return true;
                }
            });

            if (invalidEmails.length > 0) {
                return this.createError({
                    message: `Invalid email(s): ${invalidEmails.join(', ')}`
                });
            }

            return true;
        }
    });
    const schema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        employerContribution: Yup.number().nullable(),
        employerFixedContribution: Yup.number().nullable(),

        // employerContribution: Yup.number().when('employerFixedContribution', {
        //     is: (employerFixedContribution: number) => employerFixedContribution === undefined,
        //     then: () =>
        //         Yup.number().required("One contribution is required"),
        //     otherwise: () =>
        //         Yup.number().nullable()
        // }),
        // employerFixedContribution: Yup.number().when('employerContribution', {
        //     is: (employerContribution: number) => employerContribution === undefined,
        //     then: () =>
        //         Yup.number().required("One contribution is required"),
        //     otherwise: () =>
        //         Yup.number().nullable()
        // }),
        signupCode: Yup.string().nullable(),
        contractInfo: Yup.string().nullable(),
        parent: Yup.object<ChildParentTenantDto>().nullable(),
        registerEmail: emailListSchema,
        orderEmail: emailListSchema,
    });

    function save(dto: TenantDto) {
        executeSave(dto).then(res => {
            notifier.success("Saved")
            executeGet()
        });
    }
    function getTenantById(id: number): ChildParentTenantDto {
        return dataAll!!.find((t: TenantDto) => t.id === id)!!
    }
    function handleParentChange(event: React.ChangeEvent<HTMLSelectElement>): ChildParentTenantDto | undefined {
        const id = Number.parseInt(event.target.value);
        if (!Number.isNaN(id)) {
            return getTenantById(id) as ChildParentTenantDto
            // setParent(getTenantById(id))
        }
        return undefined
    }

    function getAvailableTenants(): TenantDto[] {
        return dataAll.filter(t => t.id !== data?.id)
    }

    function contractConfig() {
        navigate(`/tenants/${tenantId}/contract-config`)
    }
    function userInfoConfig() {
        navigate(`/tenants/${tenantId}/user-info-config`)
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Button variant="primary" onClick={contractConfig}>
                            Contract config
                        </Button>
                        <Button variant="primary" onClick={userInfoConfig}>
                            User info config
                        </Button>
                    </Col>

                </Row>
                <Formik initialValues={data} validationSchema={schema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            {/*<Row>{JSON.stringify(values)}</Row>*/}
                            <FormGroup>
                                <FormLabel>Title</FormLabel>
                                <Field name="title" value={values.title} as={Form.Control}
                                       isInvalid={touched.title && !!errors.title} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.title}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Contribution</FormLabel>
                                <Field type="number" name="employerContribution" value={values.employerContribution} as={Form.Control}
                                       isInvalid={touched.employerContribution && !!errors.employerContribution} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.employerContribution}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Fixed Contribution</FormLabel>
                                <Field type="number" name="employerFixedContribution" value={values.employerFixedContribution} as={Form.Control}
                                       isInvalid={touched.employerFixedContribution && !!errors.employerFixedContribution} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.employerFixedContribution}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Signup code</FormLabel>
                                <Field name="signupCode" value={values.signupCode} as={Form.Control}
                                       isInvalid={touched.signupCode && !!errors.signupCode} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.signupCode}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Contract Info</FormLabel>
                                <FormControl as="textarea"
                                       style={{
                                           height: '100px', // This can be set based on your design requirements
                                           overflowY: 'auto',
                                           resize: 'vertical' // This allows users to resize the textarea vertically
                                       }}
                                       name="contractInfo" value={values.contractInfo} onChange={handleChange}
                                       isInvalid={touched.contractInfo && !!errors.contractInfo}/>
                                <FormControl.Feedback type="invalid">{errors.contractInfo}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>Parent company</FormLabel>
                                <FormSelect
                                    name="parent"
                                    aria-label="Parent" style={{borderRadius: '50rem'}}
                                    className="form-select-sm"
                                    value={values.parent?.id} onChange={event => {
                                        setFieldValue('parent', handleParentChange(event));
                                    }}>
                                    <option key={-1} value="">Select parent</option>
                                    {getAvailableTenants().map((p, index) => (
                                        <option key={index}
                                                value={p.id}>{p.title}</option>
                                    ))}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">
                                    {errors.parent}
                                </FormControl.Feedback>
                            </FormGroup>

                            <FormGroup>
                                <FormLabel>Register email ';' separated</FormLabel>
                                <Field name="registerEmail" value={values.registerEmail} as={Form.Control}
                                       isInvalid={touched.registerEmail && !!errors.registerEmail} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.registerEmail}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Order email ';' separated</FormLabel>
                                <Field name="orderEmail" value={values.orderEmail} as={Form.Control}
                                       isInvalid={touched.orderEmail && !!errors.orderEmail} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.orderEmail}</FormControl.Feedback>
                            </FormGroup>
                            <TenantBooleanRadioFormGroup name={"requireConfirmation"} value={values.requireConfirmation} setFieldValue={setFieldValue} touched={touched} errors={errors}/>
                            <TenantBooleanRadioFormGroup name={"useGrossVehiclePrice"} value={values.useGrossVehiclePrice} setFieldValue={setFieldValue} touched={touched} errors={errors}/>


                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                {loadingSave && (<Loading/>)}
                                <Button disabled={loadingSave} variant="primary" type="submit">
                                    Submit
                                </Button>
                                {/*<Button variant="primary" onClick={() => handleSubmit()}>*/}
                                {/*    Submit2*/}
                                {/*</Button>*/}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}